import React from 'react'
import { Link } from 'gatsby'
import * as style from '../styles/blog.module.css'

export default function BlogPostPreview({ frontmatter, excerpt, slug }) {
  const { title, author, date } = frontmatter

  return (
    <div className={style.post_preview}>
      <div className={style.post_title}>{title}</div>

      <div className={style.post_author}>
        by {author} - {date}
      </div>

      <div className={style.post_excerpt}>"{excerpt}"</div>

      <Link to={`/blog${slug}`} className={style.post_link}>
        Continue Reading
      </Link>
    </div>
  )
}
