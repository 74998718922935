// extracted by mini-css-extract-plugin
export var main = "blog-module--main--3clN1";
export var headline = "blog-module--headline--KiSDh";
export var post_preview = "blog-module--post_preview--3iiHO";
export var post_title = "blog-module--post_title--33VHj";
export var post_author = "blog-module--post_author--1lecv";
export var post_excerpt = "blog-module--post_excerpt--8hnF7";
export var post_link = "blog-module--post_link--fvVyC";
export var post_main = "blog-module--post_main--ruRXE";
export var post_contents = "blog-module--post_contents--23CpM";
export var post_text = "blog-module--post_text--2vF8M";
export var recent_posts = "blog-module--recent_posts--3NxnP";
export var recent_posts_headline = "blog-module--recent_posts_headline--1jkqe";
export var recent_post_link = "blog-module--recent_post_link--1c2S7";
export var placeholder = "blog-module--placeholder--3-4VO";